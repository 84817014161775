<template>
    <div>
        <v-toolbar class="custom-toolbar" density="compact">
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon @click="openHelpDialog" color="brown-darken-2" size="large">mdi-help-box</v-icon>
            </v-btn>
        </v-toolbar>
        <div>
            <!-- Search textarea -->
            <v-row dense class="mt-3 mx-3">
                <v-col cols="12" md="8">
                    <v-textarea v-model="query" variant="outlined" label="טקסט לחיפוש" rows="2" no-resize
                        append-inner-icon="mdi-magnify" @click:append-inner="searchClicked"
                        placeholder="מנוע חיפוש למשפט עברי. מה השאלה המשפטית שלכם?" @keydown.enter.prevent="searchClicked" clearable
                        hide-details ref="searchInput"></v-textarea>
                </v-col>
                <v-col class="hidden-sm-and-down" cols="2">
                    <v-btn variant="tonal" size="small" color="brown" @click="openQueriesDialog()">חיפושים
                        לדוגמא</v-btn>
                </v-col>
            </v-row>
            <!-- Search settings: engine, num results, highlight keywords -->
            <v-row dense class="mx-3">
                <!-- Select book -->
                <v-col cols="3" md="2">
                    <v-select :disabled="true" v-model="selectedBook" :items="bookOptions" item-title="text" item-value="value"
                        return-object hide-details density="compact" variant="outlined"></v-select>
                </v-col>
                <!-- Select number of results -->
                <v-col cols="5" md="2">
                    <v-btn-toggle v-model="numResults" mandatory variant="outlined" divided density="compact">
                        <v-btn v-for="value in [10, 20, 30]" :key="value" :value="value">{{ value }}</v-btn>
                    </v-btn-toggle>
                </v-col>
                <!-- Group by article, hidden on mobile -->
                <v-col class="hidden-sm-and-down" cols="2">
                    <v-btn :color="grouped ? 'brown' : 'white'" icon size="small" @click="grouped = !grouped">
                        <v-icon>mdi-book-open-variant</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <!-- Search results -->
            <v-row v-if="shortQuery" class="mx-10"><span class="text-red text-body-2">
                    לקבלת תוצאות משופרות נסו
                    להגדיל את מספר מילות החיפוש לארבע לפחות.</span></v-row>
            <v-row v-if="!grouped" class="mx-3">
                <v-col v-for="(result, index) in results" :key="index" cols="12" md="8">
                    <v-card variant="tonal">
                        <v-card-title class="text-start">
                            <span v-if="result.title">{{ index + 1 }}. {{ result.title }}</span>
                            <span v-else>{{ result.ref }}</span>
                        </v-card-title>
                        <v-card-text class="text-start text-body-1">
                            <div v-html="result.text"></div>
                            <div v-html="result.context" class="mt-3 text-grey-darken-1 text-body-2"></div>
                            <div v-if="result.showAbstract" v-html="result.abstract" class="mt-3 text-grey-darken-1 text-body-2"></div>
                            <div v-if="result.showAbstract" class="mt-3 text-grey-darken-1 text-body-2">*התקציר הוכן על ידי AI ולכן ייתכנו אי-דיוקים.</div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" :href="openMakor(result.ref)" target="_blank">פתח מאמר</v-btn>
                            <v-btn v-if="result.abstract" color="primary" @click="result.showAbstract = !result.showAbstract">
                                {{ result.showAbstract ? 'הסתר תקציר' : 'הצג תקציר' }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <span class="mt-3 text-grey-darken-1 text-body-2">{{ result.ref }}</span>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else class="mx-3">
                <v-col cols="8">
                    <v-list>
                        <v-list-group v-for="(group, article) in groupedResults" :key="article" :value="article">
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" prepend-icon="mdi-text-box-outline">
                                    <v-list-item-title class="text-start text-subtitle-1">{{ article }} ({{ group.length
                                        }})</v-list-item-title>
                                </v-list-item>
                            </template>

                            <v-list-item v-for="(result, i) in group" :key="i" :value="result.title"
                                :title="result.title">
                                <v-card variant="flat">
                                    <!-- <v-card-title class="text-start text-subtitle-1">{{ result.ref }}</v-card-title> -->
                                    <v-card-text class="text-start text-body-1">
                                        <div v-html="result.text"></div>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="primary" :href="openMakor(result.ref)" target="_blank">פתח
                                            מאמר</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-col>
            </v-row>
        </div>
        <!-- Help dialog -->
        <v-dialog v-model="helpDialog" max-width="550px">
            <v-card>
                <v-card-title class="mt-2">
                    <span class="text-h5 text-brown">אודות מנוע החיפוש</span>
                </v-card-title>
                <v-card-text class="mr-2">
                    <ul>
                        <li v-for="(line, index) in helpContent" :key="index">{{ line }}</li>
                    </ul>
                </v-card-text>
                <v-card-actions style="direction: ltr;">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="helpDialog = false">סגירה</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Example Queries Dialog -->
        <v-dialog v-model="queriesDialog" max-width="550px">
            <v-card>
                <v-card-title class="mt-2">
                    <span class="text-h5 text-brown">חיפושים לדוגמא</span>
                </v-card-title>
                <v-card-text class="mr-2">
                    <ul>
                        <li v-for="(line, index) in exampleQueries" :key="index">
                            <a href="#" @click.prevent="selectExampleQuery(line)">{{ line }}</a>
                        </li>
                    </ul>
                </v-card-text>
                <v-card-actions style="direction: ltr;">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="queriesDialog = false">סגירה</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { search } from '@/utils/search.js';
import { groupBy } from 'lodash';

export default {
    data() {
        return {
            query: '',
            results: [],
            engine: 'milvus',  // default search engine
            numResults: 10,  // default number of results
            grouped: false,
            newSearch: false,
            helpDialog: false,
            queriesDialog: false,
            helpContent: [],
            exampleQueries: [],
            selectedBook: { text: 'חוות דעת', value: 'wygoda1' },
            bookOptions: [
                { text: 'חוות דעת', value: 'wygoda1' },
                { text: 'פרשת השבוע', value: 'wygoda2' },
            ]
        };
    },
    computed: {
        shortQuery() {
            return this.query.trim().split(' ').length < 4 && this.newSearch;
        },
        openMakor() {
            return (ref) => {
                return `https://mekorot.cs.technion.ac.il/article/${ref}`;
            };
        },
        groupedResults() {
            return groupBy(this.results, 'title');
        },
        isMobile() {
            return this.$vuetify.display.mobile;
        },
    },
    watch: {
        query() {
            this.newSearch = false;
        },
    },
    methods: {
        async searchClicked() {
            this.newSearch = true;
            // Blur the input field (remove focus) to close the keyboard on mobile
            this.$refs.searchInput.blur();
            const rawResults = await search(this.query, this.engine, this.numResults, this.selectedBook.value);

            // add to each result "showAbstract" property set to false
            rawResults.forEach(result => result.showAbstract = false);

            this.results = rawResults;
        },
        // processResults(rawResults) {
        //     // Add "article" to each result so the user will be able to group the results by article
        //     return rawResults.map(result => {
        //         const parts = result.ref.split('_');
        //         return { ...result, article: parts.slice(0, 2).join('_') };
        //     });
        // },
        removeNiqqud(text) {
            return text.normalize('NFD').replace(/[\u0591-\u05C7]/g, '');
        },
        async openHelpDialog() {
            const response = await fetch('/helpMishpatIvri.txt');
            const text = await response.text();
            this.helpContent = text.split('\n');
            this.helpDialog = true;
        },
        async openQueriesDialog() {
            const response = await fetch('/queriesMishpatIvri.txt');
            const text = await response.text();
            this.exampleQueries = text.split('\n');
            this.queriesDialog = true;
        },
        selectExampleQuery(query) {
            this.queriesDialog = false;
            this.query = query;
            this.searchClicked();
        },
    },
};
</script>

<style scoped>
.custom-toolbar {
    background: url('~@/assets/bavli_header.png') repeat-x;
    background-blend-mode: multiply;
    background-color: #D7CCC8;
}

.rating-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 250px;
    /* create brown border on all sides, with radius */
    border: 0.7px solid #795548;
    border-radius: 10px 10px 0 0;
    margin-left: 10px;
    margin-bottom: 10px;
}
</style>