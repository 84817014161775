export async function search(query, engine = 'bavli', size = 5, book = 'bavli') {
    // Encode the query to ensure it's safe to include in a URL
    //const encodedQuery = encodeURIComponent(query);

    // Construct the URL for the search request
    //const apiUrl = process.env.NODE_ENV === 'production'
    // const apiUrl = process.env.NODE_ENV !== 'production'
    //     ? process.env.VUE_APP_SEARCH_API_URL_DEPLOY
    //     : process.env.VUE_APP_SEARCH_API_URL_LOCALHOST;

    // for now all search requests are going to the same endpoint (the server)
    const apiUrl = process.env.VUE_APP_SEARCH_API_URL_DEPLOY
    //const url = `http://localhost:8000/search/${engine}?q=${encodedQuery}&size=${size}`;
    const url = `${apiUrl}/${engine}?q=${encodeURIComponent(query)}&size=${size}&book=${book}`;

    try {
        // Send the search request
        const response = await fetch(url);

        // Check if the request was successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse the JSON response
        const data = await response.json();

        // The 'data' variable contains the array of search results
        // You can process it here or return it to be processed elsewhere
        return data;
    } catch (error) {
        // Handle any errors that occurred while sending the request
        console.error('There was a problem with the fetch operation:', error);
    }
}

export async function sendFeedback(query, rating) {
    // Construct the URL for the feedback request
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_FEEDBACK_API_URL_DEPLOY
        : process.env.VUE_APP_FEEDBACK_API_URL_LOCALHOST;
    const url = `${apiUrl}?query=${encodeURIComponent(query)}&rating=${rating}`;

    try {
        // Send the feedback request
        const response = await fetch(url);

        // Check if the request was successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error('There was an error sending the feedback:', error);
    }
}